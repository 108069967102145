import * as React from 'react';
import _ from 'lodash';
import { ContentFormType, IContents } from '../types/content/contentTypes';
import { ActionIcon, Badge, Box, Button, Card, Center, Flex, TextInput, Tooltip, useMantineTheme } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { TagSelection } from './TagSelection';
import { LanguageStatusSelection } from './LanguageStatusSelection';
import { CategorySelection } from './CategorySelection';
import { useContentStore } from '../store/content/useContentStore';
import { EditorState } from 'draft-js';
import { calculateReadingTime } from '../utils/content/contentUtils';

interface ContentInfoCardProps {
  value: EditorState;
  tagsData?: { value: string; label: string }[];
  isSourceLanguage: boolean;
  selectedLanguage: { label: string; value: string };
  handleCreateContent: () => void;
  handleRemoveLanguage: () => void;
  publishDisabled?: boolean;
  selectedCategoryId: string;
}

export const ContentInfoCard: React.FC<ContentInfoCardProps> = ({
  value,
  isSourceLanguage,
  selectedLanguage,
  handleCreateContent,
  handleRemoveLanguage,
  publishDisabled,
  selectedCategoryId,
}) => {
  const theme = useMantineTheme();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, setState] = React.useState({
    isTitleFocused: false,
    isDescFocused: false,
  });

  const values = useContentStore((s) => s.values);
  const setValues = useContentStore((s) => s.handleSetValues);

  const contentTitle = React.useMemo(
    () => values.contents?.find((x) => x?.language?.value === selectedLanguage?.value)?.title,
    [values.contents, selectedLanguage]
  );
  // const contentDescription = React.useMemo(
  //   () => values?.contents?.find((x) => x?.language?.value === selectedLanguage?.value)?.description,
  //   [values.contents, selectedLanguage]
  // );
  const contentStatus = React.useMemo(
    () => values?.contents?.find((x) => x?.language?.value === selectedLanguage?.value)?.status,
    [values.contents, selectedLanguage]
  );

  const contentIndex = React.useMemo(
    () => values?.contents?.findIndex((x) => x?.language?.value === selectedLanguage?.value),
    [values.contents, selectedLanguage]
  );

  const handleBlur = (name: keyof typeof state): void => {
    setState((prev) => ({ ...prev, [name]: false }));
  };

  const handleChangeCategory = (value: string | null): void => {
    // setFieldValue('categoryId', value);
    setValues({ ...values, categoryId: value ?? '' });
  };

  const handleChangeStatus = (value: IContents['status']): void => {
    const theContents = _.cloneDeep(values.contents);
    const findIndex = theContents.findIndex((x) => x.language.value === selectedLanguage.value);
    theContents[findIndex].status = value;
    setValues({ ...values, contents: theContents });
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    const theContents = _.cloneDeep(values.contents);
    theContents[contentIndex].title = value;
    setValues({ ...values, contents: theContents });
  };

  return (
    <Card shadow="md" withBorder style={{ overflow: 'visible' }}>
      <Center mb={4} inline style={{ width: '100%', justifyContent: 'space-between' }}>
        <Flex align="center" justify="flex-start" style={{ width: '100%' }}>
          <Box style={{ width: '32.25%', minWidth: '20%', maxWidth: '32.25%' }}>
            <TextInput
              value={contentTitle}
              onChange={handleChangeTitle}
              onBlur={() => handleBlur('isTitleFocused')}
              name={`contents[${contentIndex}].title`}
              placeholder="Add title"
            />
          </Box>
          <CategorySelection
            selectedLanguage={selectedLanguage}
            disabled={!isSourceLanguage}
            value={values.categoryId || selectedCategoryId}
            onChange={handleChangeCategory}
            errorText={''}
          />
        </Flex>
        <Flex w={'100%'} align="center" justify="end">
          {!isSourceLanguage && (
            <Tooltip label="Remove language">
              <ActionIcon
                variant="subtle"
                mr={8}
                onClick={handleRemoveLanguage}
                size="medium"
                color={theme.colors.red[6]}
                p={4}
              >
                <IconTrash size={24} />
              </ActionIcon>
            </Tooltip>
          )}

          <Button
            variant="filled"
            size="sm"
            ml={8}
            bg={publishDisabled ? theme.colors.gray[3] : theme.colors.green[6]}
            onClick={handleCreateContent}
            disabled={publishDisabled}
          >
            {values.formType === ContentFormType.UPDATE ? 'Update' : 'Publish'}
          </Button>
        </Flex>
      </Center>
      <Flex align="center" justify="space-between" py={4}>
        {/* <Center inline>
          {!state.isDescFocused ? (
            <Text
              variant="text"
              size="md"
              onClick={() => {
                handleFocus('isDescFocused');
              }}
            >
              {contentDescription}
            </Text>
          ) : (
            <Box>
              <TextInput
                autoFocus
                value={contentDescription}
                onChange={handleChangeDesc}
                onBlur={() => handleBlur('isDescFocused')}
                name={`contents[${contentIndex}].description`}
              />
            </Box>
          )}
          <Tooltip label="Edit description">
            <ActionIcon
              variant="subtle"
              ml={8}
              onClick={() => handleFocus('isDescFocused')}
              color={theme.colors.blue[6]}
            >
              <IconPencil size={18} />
            </ActionIcon>
          </Tooltip>
        </Center> */}

        <Center inline>
          <TagSelection disabled={!isSourceLanguage} errorText={''} />
          {contentStatus && (
            <LanguageStatusSelection
              isCreating
              value={contentStatus}
              onChange={(val: IContents['status']) => handleChangeStatus(val)}
            />
          )}
        </Center>

        <Badge radius="lg" variant="filled">
          {calculateReadingTime(value.getCurrentContent().getPlainText())} est. reading time
        </Badge>
      </Flex>
    </Card>
  );
};
